
import { Component, Vue, Watch } from "vue-property-decorator";
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import SearchBar from "@/components/SearchBar.vue";

import { namespace } from "vuex-class";

@Component({
  components: { Header, Footer, SearchBar },
})
export default class Layout extends Vue {
  searchQuery = "";

  @Watch("$route")
  onRouteChange(): void {
    if (!this.$route.fullPath.includes("search-result")) {
      (this.$refs.searchBar as any).clearSearchQuery();
    }
  }

  onSearchclick(payload: string): void {
    this.searchQuery = payload;
    this.$router
      .push("/home/brain-library/search-result/search-result")
      .catch(() => {
        console.log("same route");
      });
  }
}
