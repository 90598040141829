
import { Component, Prop, Vue } from "vue-property-decorator";
import BaseInput from "@/components/BaseInput.vue";

@Component({
  components: {
    BaseInput,
  },
})
export default class SearchItemInput extends Vue {
  @Prop() value?: string;
  @Prop() inputPlaceholder?: string;
  @Prop() disableButtton?: boolean;
}
