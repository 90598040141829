
import { Component, Vue, Watch } from "vue-property-decorator";
import SearchItemInput from "@/components/SearchItemInput.vue";
import BaseButton from "@/components/BaseButton.vue";

@Component({
  components: { SearchItemInput, BaseButton },
})
export default class SearchBar extends Vue {
  searchQuery = "";
  onInvestmentPhilosophiClick(): void {
    this.$router
      .push({
        name: "InvestmentsPhilosophy",
      })
      .catch(() => {
        console.log("same-route");
      });
  }
  onSearchclick(): void {
    this.$emit("searchClick", this.searchQuery);
  }
  clearSearchQuery(): void {
    this.searchQuery = "";
  }
}
